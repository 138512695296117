<script lang="ts">
	import { classes } from '$lib/utils';

	export let type: 'button' | 'reset' | 'submit' | null | undefined = 'button';
	export let additionalClass = '';
	export let disabled = false;
	export let design: 'primary' | 'secondary' | 'white' = 'primary';
</script>

<button
	{type}
	{disabled}
	on:click
	class={classes(
		'min-w-fit px-[10%] md:px-24 py-3 inline-flex items-center justify-center rounded-full border border-transparent text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
		disabled ? 'bg-gray-300' : '',
		design === 'primary' ? 'bg-purple text-lemon hover:bg-purple-hover focus:ring-purple' : '',
		design === 'secondary' ? 'bg-lemon hover:bg-lemon-hover text-purple focus:ring-lemon ' : '',
		design === 'white'
			? 'bg-white hover:bg-gray-100 text-purple focus:ring-gray-100 border-purple'
			: '',
		additionalClass
	)}
	{...$$restProps}
>
	<slot />
</button>
